.verify{
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #efefef;
    .v-box{
        height: 100px;
        max-width: 400px;
        width:100%;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
        background: #fff;
        padding: 10px;
        text-align: center;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: 2px dashed rgb(179, 179, 179);
        outline-offset: 15px;

    }
    h4{
        font-size: 20px;
        color:#7a869a;
    }
}