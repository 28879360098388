@import '../../../../assets/scss/index.scss';
.pagination{
    font-family: sans-serif;
    margin-top: 15px;
    @include flex-center;
    .pagination_eclipse{
        list-style-type: none;
        cursor: pointer;
        padding: 5px;
        &:hover{
            transform: scale(1.1);
            color: $green;
        }
    }
    .pagination_item{
        // display: inline-block;
        list-style-type: none;
        cursor: pointer;
        padding: 10px;
        margin: 5px;
        border: 1px solid $green;
        border-radius: 50%;
        color: #303030;
        &.active{
            filter: opacity(1);
            transform: scale(1.15);
        }
        &.inactive{
            filter: opacity(0.75);
        }
        &:hover{
            transform: scale(1.1);
            background: #e7fffb;
        }
    }
}
.load{
    @include flex-btw;
    .load_btn{
        @include bannerButtons($width:85px);
    }
}

.pagination2
{
    @include flex-center;

    .buttonPage
    {
        background-color:$lightgreen;
        color:$black;
        padding:5px 10px;
        border-radius: 8px;

        &.activeBtn
        {
            background-color:$green;
            pointer-events: none;
        }
    }
}
