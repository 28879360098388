@import "../../assets/scss/index.scss";

.Navlogo
{
    position: fixed;
    top: 0;
    left:0;
    right: 0;
    z-index: 99;
    background: transparent;
    transition: all 0.2s ease-in-out;

    &.scrolled
    {
        background:#fff ;
        box-shadow: $header-shadow;

        .centerHome
        {
            text-align: start;
        }

        .innerWrapper
        {
            height: 55px;
            text-align: start;
            @include flex-btw;

            .homelink
            {
                margin: 10px 0;
                transform: scale(0.8) translateX(-14%);
            }
        }
    }

    .innerWrapper
    {
        @include flex-btw;
        max-width: 1300px;
        margin: auto;
    }

    .centerHome
    {
        text-align: center ;

        @include mq("tablet", "min") 
        {
           text-align: start !important;
        }
    }

    .homelink
    {
        position: relative;
        display: inline-block;
        margin: 3vh 0vw ;
        max-width: 160px;
        
        @media screen and (width:768px)
        {
            margin: 3vh 0vw ;
        }


        @include mq("desktop-med", "min") 
        {
            margin: 3vh 0vw ;
        }

        
        @include mq("desktop-wide", "min") 
        {
            margin: 3vh 0vw ;
        }

        @include mq("excess", "min") 
        {
            margin: 3vh 0vw ;
        }
        
        .beta
        {
            position: absolute;
            background-color: #33d5ba;
            color:#fff;
            font-weight: bold;
            border-radius: 5px;;
            padding: 5px;
            transform: scale(0.8);           

            @media screen and (min-width:768px) and (max-width:780px) 
            {
                transform: scale(0.6);
                right: -38px;
                top: -5px;
            }

        }
    }

    
    .links
    {
        border-top: 1px solid #ccc;
        list-style-type: none;
        font-family: Lato;
        font-size: 16px; 
        line-height: 1.75;
        letter-spacing: normal;
        text-align: left;            
        font-weight: bold;
        position: fixed;
        right: 0;
        left: 0;
        top:50px;
        background: #fff;
        height: 300px;
        transition: transform 0.2s ease-in-out;
        //width: 200px;
        @include flex-btw(column);
        transform: translateX(100%);

        &.open
        {
            transform: translateX(0%);
        }

        li
        {
            margin: auto 5px;
            a
            {
                color: $black;
                cursor: pointer;
                
                @include mq("tablet", "min") 
                {
                    padding: 5px 2px;
                }
                
                @include mq("tablet-wide", "min") 
                {
                    padding: 5px 15px;
                }

                @media screen and (min-width:768px) and (max-width:900px) 
                {
                    font-size:14px;                    
                }
            }
        }

        .activeLink
        {
            color: $green;           
        }

        @include mq("tablet","min")
        {
            border: none;
            transform: translateX(0%);
            width: unset;
            height: unset;
            position: initial;  
            background: none;          
            @include flex-btw;
        }

    }

    .menu 
    {
        display: inline-block;
        margin-right: 10px;
        background: transparent;
        color:$green;
        border: 1px solid $green;
        padding: 3px 8px;
        border-radius: 5px;
      

        @include mq("tablet", "min") 
        {
           display: none;
        }
    }
}
