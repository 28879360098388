@import "../../assets/scss/index.scss";

.Home {
  color: $black;
  background-color: #f9f9f9;
  .hero {
    padding-top: 45px;
    background-position: top right;
    background-size: 350px;
    background-repeat: no-repeat;
    background-origin: padding-box;
    // background-image: url("../../assets/images/bg-mobile-2.png");

    @include mq("phablet", "min") {
      min-height: 500px;
    }
  }

  .assurance {
    background-color: #fff;
    text-align: center;
    min-height: 20vh;
    padding-top: 50px;
    padding-bottom: 50px;
    &__box {
      max-width: 1040px;
      margin: 50px auto 0px;
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      justify-content: space-between;
      @media (max-width: 936px) {
        justify-content: space-evenly;
        &__item {
          text-align: center;
        }
      }
      &__item {
        margin: 20px;
        h3 {
          margin-top: 20px;
          font-size: 20px;
          font-weight: 600;
          line-height: 1.5;
          letter-spacing: 1.2px;
          text-align: left;
          color: #303030;
        }
      }
    }
    @include mq("tablet", "min") {
      padding: 50px 10vw 100px;
    }
  }
  .steps {
    margin-top: 40px;
    padding-bottom: 50px;
    .steps_info {
      display: flex;
      width: 100%;
      & > div {
        width: 100%;
      }
      .list {
        padding-top: 150px;
      }
      a {
        margin-top: 40px;
      }
      @include mq("tablet-wide") {
        .frame svg {
          width: 456px;
        }
      }
      @include mq("fluid-out") {
        .list ul li span {
          font-size: 18px;
        }
        .frame svg {
          width: 400px;
        }
      }
      @include mq("tablet") {
        flex-flow: column;
        .list ul{
          margin: 0 auto;
          max-width: 500px;
        }
        &.inverse {
          flex-flow: column-reverse;
        }
        .frame {
          text-align: center;
          svg {
            width: 227px;
            height: 326px;
            margin: 0 auto;
          }
        }
        .list {
          padding-top: 70px;
          ul li span {
            font-size: 16px;
          }
        }
        h3,
        a {
          text-align: center;
        }
        a {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }

        padding: 0px 5px;
      }
    }
    .send {
      margin-bottom: 40px;
      @include mq("tablet", "min") {
        margin-bottom: 0px;
        .list {
          margin-left: 30px;
        }
      }
    }
  }
  .bg-color {
    text-align: center;
    @include mq("phone-wide") {
      svg {
        max-width: 80%;
      }
    }
  }
  .customers {
    padding: 100px 0px 0;
    .title {
      max-width: 622px;
      margin: 0 auto;
    }
  }
}

.conveter {
  min-height: 158px;
  margin-top: 20px;
}

%btn {
  padding: 14.3px 30.6px;
  border-radius: 4px;
}
.btn-green {
  @extend %btn;
  width: 200px;
  height: 54px;
  text-align: center;
  color: #fff;
  background-color: $green;
  font-weight: bold;
  font-size: 18px;
}

.title {
  font-family: Raleway;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #303030;
  @include mq("phone-wide") {
    font-size: 25px;
  }
}

.pagewrap {
  max-width: 1165px;
  margin: 0 auto;
  padding: 0px 40px;
  @include mq("phone-wide") {
    padding: 0px 20px;
  }
  @include mq("phone") {
    padding: 0px 10px;
  }
}

.token {
  margin-top: 20px;
  padding-top: 70px;
}

.token-flex {
  font-family: Raleway;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 1.2px;
  text-align: left;
  color: $black;
  margin-top: 30px;
  padding-bottom: 30px;
  @include mq("tablet", "min") {
    font-size: 20px;
    @include flex-btw;
    flex-flow: row-reverse;
    margin-top: 54px;
    padding-bottom: 70px;
  }

  article {
    padding: 10px;
    @include mq("tablet", "min") {
      padding: 0 20px 0 0;
    }

    @include mq("tablet-wide", "min") {
      padding: 0 70px 0 0;
    }
  }

  .btn-green {
    display: block;
    margin: auto;
    margin-top: 40px;
    padding: 14.3px 25.6px;

    @include mq("tablet", "min") {
      margin: initial;
      margin-top: 40px;
      min-width: 250px;
    }
  }

  .share-logo {
    margin-top: 30px;
    @include flex-center();
    img{
      max-width: 460px;
      margin:0 auto;
    }

    @include mq("tablet", "min") {
      margin-top: 0px;
      min-width: 250px;
    }

    @include mq("tablet-wide", "min") {
      min-width: 370px;
    }
  }
}
.as-seen {
  padding: 40px 0;
  .title {
    margin-top: 101px;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #303030;
  }
  .press {
     margin: 30px 0 78px;
    padding: 21px 120.1px 19px 124px;
    @include mq("phone-small") {
    //  padding-top: 20px;
    }
    .row_1,
    .row_2 {
      @include flex-even();
      @include mq('tablet'){
        display: flex;
        flex-direction: column;
      }
      @include mq("phone-small") {
        display: flex;
        flex-direction: column;
      }
      @include mq("phone-wide") {
        display: flex;
        flex-direction: column;
      }
      @include mq("phone") {
        display: flex;
        flex-direction: column;
      }
      @include mq("phablet") {
        display: flex;
        flex-direction: column;
      }
      .press-image {
        max-width: 100%;
        object-fit: contain;
     //   margin-bottom: 30px;
        @include mq('tablet'){
          display: flex;
          margin-bottom: 50px;
        }
      }
    }
    .row_2 {
      margin-top: 100px;
      @include mq('tablet'){
      margin-top:0 ;
      }
      @include mq("phone-small") {
        margin-top: 0;
      }
      @include mq("phone-wide") {
        margin-top: 0;
      }
      @include mq("phone") {
        margin-top: 0;
      }
      @include mq("phablet") {
        margin-top: 0;
      }
    }
  }
  #nairametrics {
    height: 30px;
  }
  #techpoint {
    height: 50px;
  }
  #pulse  , #guardian , #nation{
    height: 56px;
  }
  #vanguardng {
    height: 43px;
  }
  #tc{
    height: 72px;
  }
  #bitcoin{
    height:87px;
  }
}
