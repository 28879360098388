.network__list{
    min-height: 32px;
    margin-top: 24.9px;

    h4{
     font-weight: 600;
     margin-bottom: 16px;
    }
   
    #{&}__item{
        height: 32px;
        width:100%;
        max-width: 176px;
        background: #e9e9e9;
        padding: 4px 29px 5px 12px;
        margin:8px auto;
        color:#303030;
        font-size: 14px;
        border-radius: 3px;
        cursor: pointer;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        
        p{
            line-height: 25px;
            &.right_p{
                text-align: right;
            }
        }
        &.selected{
            border:1px solid #33d5ba;
            background: #33d5ba;
            position: relative;
            &::after{
                 content: "\2713";
                position: absolute;
                height: 20px;
                width:20px;
                border-radius: 100%;
                right: -10px;
                top:-10px;
                background:#ffffff;
                color:rgba(48, 48, 48, 0.5);
                box-shadow:0px 0px 20px rgba(48, 48, 48, 0.2);
                text-indent: 5px;
                font-weight: 600;
            }
        }
         &.head{
        background: none;
        height: auto;
        margin:0 auto;
        padding:1px;
       p{
           font-size: 10px;
           line-height: normal;
           color:rgba(48, 48, 48, 0.5);
           font-weight: bold;
       }
    }
    }
}