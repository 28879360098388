
.sidebar_item{
    
    a{
        display: block;
        padding: 23px 32px;
        font-size:16px;
        color: #686c73;
        font-weight: 600;
        span {
            i{
            margin-right: 20px;
            font-size: 20px;
        }
    }
    }
    &:hover, &.active{
       background-color: #f6fcfc;
       a{
           color:#33d5ba;
       }
    }
    &.active{
         border-right: 5px solid #33d5ba;
    }
   
}