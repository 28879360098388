.afiliate_header {
  display: flex;
  justify-content: space-between;
  font-family: "Raleway";
  align-items: center;
  min-height: 70px;
  margin-bottom: 30px;
  .header_end {
      display: flex;
      align-items: center;
    .search {
      background: #fff;
      height: 48px;
      max-width: 328px;
      width: 100%;
      border-radius: 34px;
      display: flex;
      align-items: center;
      padding: 0px 23px;
      input {
        height: 90%;
        border-top-left-radius: 34px;
        border-bottom-left-radius: 34px;
        border: none;
        width: 100%;
        font-size: 18px;
        color: #b8b8b8;
        font-weight: 400;
        &::placeholder{
           font-size: 18px;
        color: #b8b8b8;
        font-weight: 400;
        }
      }
      i {
        font-size: 20px;
        color: #b8b8b8;
      }
    }

    .notification_icon{
       width: 50px;
       margin-left: 20px;
       span{
           font-size: 30px;
           position: relative;
           color:#33d5ba;
           small{
               position: absolute;
               min-width: 15px;
               height: 15px;
               text-align: center;
               line-height: 13px;
               border-radius: 100%;
               font-size: 10px;
               left: -3px;
               background: #F23232;
               color:#fff;
               padding: 0 5px;
           }
       }

    }
  }

  .mobile_toggler {
    margin-right: 10px;
    .toggler {
      padding: 10px;
    //   background: #fff;
      border-radius: 10px;
      width: 70px;
      height: 70px;
      font-size: 30px;
    }
  }
  @media only screen and (min-width: 46.875em) {
    .mobile_toggler {
      visibility: hidden;
    }
  }
}
