.table {
  padding: 32px 40px;
  background-color: #fff;
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  width: 100%;
  .table_title {
    display: flex;
    h3 {
      font-size: 24px;
      font-weight: 600;
    }
    h6{
      font-size: 20px;
      font-weight: 600;
      filter: opacity(0.8);
      position: relative;
      left: 25px;
      font-family: cursive;
      color: #303030;

    }
  }
}

.table td,
.table th {
  vertical-align: middle;
  img {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    vertical-align: middle;
  }
}
.table thead th {
  vertical-align: bottom;
  color: #30303080;
  font-size: 14px;
  font-weight: 600;
}
.table tbody tr {
  background-color: #e7fffb;
}

table {
  border-collapse: separate;
  border-spacing: 0 1em;
  margin: 0;
  padding: 0;
  width: 100%;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  margin-bottom: 10px;
  height: 60px;
}

table th,
table td {
  height: auto;
  text-align: center;
  color: #303030;
  font-size: 16px;
  min-width: 140px;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.empty
{
  margin: 20px 0;
  font-size: 20px;
}
