@import "../../../assets/scss/index.scss";
.Home{
  .banner {
  background-color: $white-bg-banner;
  padding: 45px 0px;
  margin-top: -0px;
  h2.header {
    font-size: 45px !important;
    font-weight: 700;
    line-height: 59px;
    font-style: normal;
    @include mq("tablet") {
      font-size: 32px !important;
      line-height: normal;
    }
  }
  .cta-row {
    width: 100%;
    display: flex;
    gap: 40px;

    .loginButton {
      @include bannerButtons();
    }
    .signupButton {
      @include bannerButtons($bgColor: $white-bg-banner, $color: $green);
    }
    @include mq("phone-small") {
      background-size: unset;
      flex-flow: column;
      align-items: center;
    }
  }
}

.dotsection {
  background-color: $white-bg-banner;
  height: 250px;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    background-image: url("../../../assets/images/dots.png");
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
}

.container {
  &.reverse{
    @include flex-btw($direction:row-reverse,$wrap:wrap);
    .img__num{
      @include mq($width:'desktop',$type:min){
        @include flex-btw();
      }
    }
  
  }
  @include flex-btw($wrap:wrap);
  // @include mq("tablet-small") {
  //   flex-flow: column;
  // }
  .img__num{
  @include mq($width:'desktop',$type:min){
    @include flex-btw($direction:row-reverse);
    img:nth-child(odd){
      margin-right:15px;
    }
    img:nth-child(2){
      margin-right:10px;
    }

  }
  }
  margin-top: 56px;
  .body {
    max-width: 480px;
    width: 100%;
    .head {
      font-weight: 700;
      font-size: 24px;
      line-height: 48.1px;
      color: $black;
    }
    .description {
      margin-top: 16px;
      line-height: 40px;
      font-size: 20px;
      font-weight: 400;
    }
    .register {
      @include bannerButtons($color: #ffffff);
      height: 40px;
      margin-top: 8px;
    }
  }
  .icon-number {
    align-self: center;
    width: 50px;
    height: 50px;
    margin: 20px 0;
  }
  img.step_picture {
    width: 50px;
    height: 50px;
  }
}

overflow-x: hidden;
}