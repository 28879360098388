@import "../../assets/scss/index.scss";

.footer
{
    font-family: Raleway;
    color: #e2e2e2;
    background:  linear-gradient(
      rgba(0, 0, 0, 0.75), 
      rgba(0, 0, 0, 0.75)
    ),url("../../assets/images/leafy.svg");
  
    background-repeat: no-repeat;
    background-position-x: 98%;    
    background-position-y: -40%;
    padding: 20px 0px 80px;
      
    @include mq("tablet", "min") 
    {
        padding: 20px 0px 70px;
    }


    @include mq("desktop-med", "min") 
    {
        padding: 60px 0px 60px;
    }


    .inner
    {
       
        margin: auto;
    }

    .links-sub
    {

        @include flex-btw(row,wrap);

        .link-section
        {
            min-width: 170px;

            &.last
            {
                align-self: flex-start;
            }

            &.form
            {
                position: relative;

                .error
                {
                    color: $red;
                    font-style: italic;
                    font-size: 14px;
                    position: absolute;
                    left:0;
                }

                form
                {
                    display: flex;
                    flex-wrap: nowrap;
                    border: 2px solid $green;
                    border-radius: 6px;
                    overflow: hidden;

                    input
                    {
                        padding: 14px;
                        border: none;
                        background-color: transparent;
                        transition: background-color 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
                    }

                    input:focus
                    {
                        background-color: #fff;
                    }

                    button
                    {
                        background-color: $green;
                        padding: 8px;
                        color: #ffffff;
                        font-weight: bold;

                        &:disabled
                        {
                            opacity: 1;
                            cursor: not-allowed;
                        }

                        @include mq("tablet","min")
                        {
                            padding: 14px;
                        }
                    }
                }
            }

            p
            {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 18px;
                margin-top: 25px;

                @include mq("desktop", "min") 
                {
                    margin-top: 0px;

                }

            }

            ul
            {
                font-size: 16px;
                list-style-type: none;
               
                a
                {
                    display: inline-block;
                    margin-bottom: 8px;
                    color: #e2e2e2;
                }

                .blog
                {
                    display: flex;
                    align-items: center;
                    position:relative;
                }

                .medium
                {
                    display: inline-block;
                    width: 35px;
                    position: absolute;
                    left: 20%;
                }
            }
        }
    }

    .social-copyright
    {
        margin: 90px auto 10px;
        @include flex-btw(row,wrap);

        .links
        {
            @include flex-btw;
            width: 100%;

            

            @include mq("tablet", "min") 
            {
                width: auto
            }


          

            a
            {
                display:inline-block;

                &.fb
                {
                    @include mq("tablet","min")
                    {
                        padding-left: 8px;
                    }
                }

                &.linkedin
                {
                    @include mq("tablet","min")
                    {
                        width: 28px;
                        margin-right: 10px;
                    }
                }

            }
        }

        p
        {
            width: 100%;
            text-align: center;
            padding-top: 12px ;
            color:#fff;
             @include mq("tablet","min")
            {                    
                width: auto;
                text-align: start;
                padding: 0;
            }
        }
    }
}

.toast
{
    background-color: $green;
    color:#fff;
    position: fixed;
    bottom: 92px;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    overflow: hidden;
    opacity: 0;
    width: 90vw;
    max-width: 350px;
    transition: opacity 0.2s ease;
    pointer-events: none;

    @include mq("tablet-small", "min") 
    {
         bottom: 20px;
    }

    @include mq("tablet", "min") 
    {
          max-width: 300px;
    }

    &.visible
    {
        z-index: 10;
        opacity: 1;
    }

    
    .content
    {
        position: relative;
        padding: 10px;
        

        .dismiss
        {
            position: absolute;
            right: 10px;
            top:5px;
            padding: 5px;
            background: #fff;
            color: $green;
            border-radius: 2px;
        }

        .text
        {
            text-align: center;
        }
        
    }
}
