.testimonial {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  @media (min-width: 1110px) {
    justify-content: space-between;
    & :first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
}
.testimonial__box {
  font-family: "Lato", Arial, sans-serif;
  padding: 20px 12px 25px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 20px;
  //   min-width: 250px;
  max-width: 310px;
  min-height: 318px;
  width: 100%;
  background-color: #fff;
  color: #303030;
  text-align: center;
  font-size: 16px;
  border-radius: 10px;
  box-shadow: -50px 65px 65px rgba(206, 206, 206, 0.37);
  blockquote {
    &::before {
      content: "\201C";
      margin-bottom: 50px;
      font-size: 100px;
      display: block;
      transform: rotate(180deg);
      line-height: 0px;
      color: inherit;
    }
    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #303030;
      font-family: Raleway;
    }

  }

  .user-name-pic
  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    
    img 
    {
      width: 60px;
      height: 60px;
      flex-grow: 0;
      border-radius: 100%;
      margin: 10px auto;
    }

    h4
    {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      text-align: center;
      color: rgba(48, 48, 48, 0.5);
    }
  }

}
