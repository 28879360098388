@import '../../SignUp/SignUp.scss';

.Affiliate_login{
    @include affiliatePageTemplate;
    .formWrap{

      .success-message{
        color: $green;
        text-align: center;
        line-height: 30px;
        position: relative;
        top: 40px;
        font-size: 0.95rem;
        p{
          font-family: cursive;
          font-size: 1rem;
        }
      }

        h3.formTitle{
          margin-bottom: 20px;
        }
        header{
            width: 236px;
            line-height: 24px;
            margin: 30px auto;
            text-align: center;
        }
        button.submit{
            margin-top: 90px;
            &#disabled{
                filter: opacity(0.4);
                &:hover{
                  transform: none;
                }
              }
        }
    }
}