@import "../../assets/scss/index.scss";


.Share
{
    position: relative;
    background-color: #fff;
    background-image: url("../../assets/images/bg-mobile.png");
    background-repeat: no-repeat;
    min-height: 100vh;
    @include flex-center;

    @include mq("phone-small")
    {
        background-size: unset;
    }  

    @include mq("tablet","min")
    {
        background-image: url("../../assets/images/bg.png");
        background-position: center;
        background-size: cover;
    }

    &:before
    {
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background:$overlay;
    }
}
