
.iconList {
  $pointer: #d6f1ff;
  $arrow: #ddf8f4;
  $share: #fff0c2;
  $copy: #fff0c2;
  $notification: #fbd9c6;
  $alpha: #e8d1ff;
  $shadow: -9px 10px 30px;

  h3 {
    font-family: Raleway;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.3;
    text-align: left;
    color: #303030;
    margin-bottom: 41px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
  .fixer {
    display: none;
  }
  list-style: none;
  li {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 24px 0px;
    span {
      margin-left: 29px;
      font-family: Raleway;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 1.2px;
      color: #303030;
    }
  }

  .icon_box {
    flex-shrink: 0;
    border-radius: 14px;
    width:54px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 400px) {
     width:34.8px;
     height:34.8px;
     border-radius: 10px;
     svg{
       width:15px;
       height: 18px;
     }
    }
  }
  .pointer {
    box-shadow: $shadow $pointer;
    background: $pointer;
  }
  .arrow {
    box-shadow: $shadow $arrow;
    background: $arrow;
  }
  .alpha {
    box-shadow: $shadow $alpha;
    background: $alpha;
  }
  .copy {
    box-shadow: $shadow $copy;
    background: $copy;
  }
  .notification {
    box-shadow: $shadow $notification;
    background: $notification;
  }
  .share {
    box-shadow: $shadow $share;
    background: $share;
  }
}
