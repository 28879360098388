@import "./assets/scss/index.scss";
*
{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body 
{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;  
  font-family: 'Raleway', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}


a
{
  text-decoration: none;
  display: inline-block;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;

}

button
{
  cursor: pointer;
  border: none;
  outline: none;
  display: inline-block;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
}

button:disabled
{
  opacity: 0.5;
}

a:hover,
button:not(:disabled):hover
{
  transform: scale(1.04);
}

input
{
  outline: none;
}

img
{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

body::-webkit-scrollbar 
{
  width: 10px;
}
 
body::-webkit-scrollbar-track 
{
  background-color: #d3efea;
}
 
body::-webkit-scrollbar-thumb 
{
  background-color: #7a869a;
}


/* FORM ROW CLASSS**/
.form-row {
  color: $black;
  position: relative;
  margin-bottom: 15px;
  ::placeholder {
    color: rgba(48, 48, 48, 0.5);
    font-size: 14px;
  }
  label {
    letter-spacing: 0.42px;
    font-weight: 600;
    margin-bottom: 6px;
    display: block;
    .info {
      color: #33d5ba;
      i {
        font-style: italic;
      }
      margin-left: 3px;
      font-size: 12px;
    }
  }

  input,
  select {
    width: 100%;
    font-family: "Raleway", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    color: #303030;
    display: block;
    padding: 15.5px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: solid 1px rgba(53, 120, 229, 0.1);
    background-color: #ffffff;
    outline: none;
  }

  select {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, $green 50%),
      linear-gradient(135deg, $green 50%, transparent 50%),
      linear-gradient(to right, #fff, #fff);
    background-position: 90%, 92%, 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;

    @media screen and (min-width: 329px) and (max-width: 339px) {
      background-position: 90%, 91.7%, 100% 0;
    }

    @include mq("phone", "min") {
      background-position: 90%, 91.4%, 100% 0;
    }

    @media screen and (min-width: 356px) and (max-width: 399px) {
      background-position: 90%, 91.7%, 100% 0;
    }

    @media screen and (min-width: 456px) and (max-width: 638px) {
      background-position: 90%, 91.2%, 100% 0;
    }
    & > option {
      opacity: 0.3;
    }
  }

  input:focus,
  select:focus {
    border: 1px solid $green;
  }

  small {
    color: $red;
    padding: 5px 0 0;
    font-weight: bold;
    font-style: italic;
  }

  .error {
    color: $red;
    padding: 10px 0 0;
    font-weight: bold;
    font-size: 12px;
  }

  .user {
    font-weight: 500;
    margin-top: 8px;
  }
}