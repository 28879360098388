@import '../../../../assets/scss/index.scss';


.refresh
{
    background: $green;
    color:$purewhite;
    padding: 10px;
    margin: 10px 0;
    font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    letter-spacing: 1px;
}
