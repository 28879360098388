@import "../../assets/scss/index.scss";

.Error
{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background: $red;
    color: #fff;
    width: 100vw;
    min-height: 50px;
    padding: 14px 10px;
    z-index: 100;

    .icon
    {
        width: 36px;
        height: 36px;
    }

    p
    {
        margin-left: 12px;
    }

    @include flex-center
}

.Boundary
{
    min-height: 100vh;
    padding: 70px 10px;
    background: $light-green;
    @include flex-center;

    .body
    {
        text-align: center;
        font-family: Raleway;

        .oops
        {
            font-size: 4.5rem;
            margin: 10px auto;
        }

        .message
        {
           font-size: 1.5rem;
           margin: 25px 0;
        }
        

        .link
        {
            background:$green;
            border-radius: 4px;
            color:#fff;
            padding: 1em 2em;
            font-size: 14px;
            margin: 10px auto;    
        }
    }
}
