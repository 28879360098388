@import "../../../../assets/scss/index.scss";
.cut-text { 
  text-overflow: ellipsis;
  overflow: hidden; 
  width: 352px; 
line-height: 21.13px;
  white-space: nowrap;
}
p#edit{
  color:$green;
  text-transform : capitalize;
}
.settings {
  margin-top: 64px;
  .settings_content {
    .title {
      h5 {
        text-align: left;
        font-size: 24px;
        line-height: 28.18px;
        color: $black;
        font-weight: 600;
        font-family: inherit;
      }
    }
    .settings_dropdowns {
      margin-top: 50px;

      button.dropdown {
        max-width: 488px;
        width: 100%;
        font-size: 18px;
        font-family: inherit;
        font-weight: 600;
        color: $black;
        line-height: 21.13px;
        padding: 26px 24px;
        height: 72px;
        box-sizing: border-box;
        margin: 0;
        border: 0;
        margin-bottom: 24px;
        background-color: $purewhite;
        .icon {
          width: 18px;
          height: 18px;
          @include mq("phone") {
            width: 10px;
            height: 10px;
          }
        }
        @include flex-btw();
        @include mq("phone") {
          font-size: 14px;
          height: 40px;
        }
p{
  text-transform: capitalize;
  &.success{
    color: $green;
  }
}
      }
      .dropdown_section {
        
        margin-bottom: 50px;
        .hide {
          display: none;
        }
        .show {
          display: block;
        }
        .active {
          background-color: $light-green !important;
        }
        #dropdown_content {
          max-width: 488px;
          margin-top: 24px !important;
          min-height: 394px;
          background: $purewhite;
          padding:30px 20px;
          @include mq("phone") {
            overflow-y: scroll;
          }
          form {
            label {
              font-weight: 600;
              font-size: 14px;
              @include mq("phone") {
                font-size: 14px;
              }
            }
            input {
              display: block;
              font-size: 16px;
              font-family: inherit;
              font-weight: 400;
              line-height: 1.3;
              padding: 0.6em 1.4em 0.5em 0.8em;
              width: 100%;
              margin-top: 6px;
              border: 1px solid rgba(53, 120, 229, 0.1);
              height: 52.08px;
              background-color: $purewhite;
              border-radius: 6px;
              background-repeat: no-repeat;
              background-position: right 0.7em top 50%, 0 0;
              background-size: 0.65em auto, 100%;
              font-size: 14px;
            }
            .error_message {
              div{
                display: flex;
                gap:10px
              }
              p#error_text{
                color:red;
                font-weight: 800;
                font-size: 14px;
              }
              p {
                font-weight: 500;
                font-size: 14px;
                letter-spacing: 4%;
                text-transform: capitalize;
                margin-top: 19px;
                
              }
            }
            .verify_message {
              div{
                display: flex;
                gap:10px
              }
              p#verify_text{
                color:rgba(48, 48, 48, 0.5);
              }
              p {
                font-weight: 500;
                font-size: 14px;
                letter-spacing: 4%;
                text-transform: capitalize;
                margin-top: 9px;
                
              }
            }
            .form_group {
              &:first-child {
                margin-bottom: 36.92px;
              }
              &:last-child {
                @include flex-center();
                margin-top: 60px;
              }
            }
            .select-css {
              display: block;
              font-size: 16px;
              font-family: inherit;
              font-weight: 400;
              line-height: 1.3;
              padding: 0.6em 1.4em 0.5em 0.8em;
              width: 100%;
              margin-top: 6px;
              border: 1px solid rgba(53, 120, 229, 0.1);
              height: 52.08px;
              -moz-appearance: none;
              -webkit-appearance: none;
              appearance: none;
              background-color: $purewhite;
              border-radius: 6px;
              background-image: url("../../../../assets/images/selectdropdownpolygon.png");
              background-repeat: no-repeat;
              background-position: right 0.7em top 50%, 0 0;
              background-size: 0.65em auto, 100%;
              text-transform: uppercase;
              font-size: 14px;
              &:focus {
                border: 1px solid rgba(53, 120, 229, 0.1);
                outline: 0;
              }
            }
            .submit_button {
              @include bannerButtons();
              font-weight: 700;
              font-size: 18px;
              color: $purewhite;
              line-height: 21.13px;
              .loader{
                margin: 0 !important;
                margin-right: 5px;
              }
              &:disabled {
                cursor: not-allowed;
              }
              @include mq('phone-small') {
                width: 150px;
              }
            }
            &#profile{
              #save_changes{
                margin-top: 46px;
                @include flex-even();
                @include mq('phone-small'){
                  justify-content:space-between;
                }
                p{
                 cursor: pointer;
                 @include mq('phone-small',min){
                   font-size: 18px;
                 } 
                 transition: transform 0.05s ease-in;
                 &:hover{
                   color: $green;
                   transform: scale(1.1);
                 }
                }
              }
              button#disabled{
               filter: opacity(0.4);
               &:hover{
                 transform: none;
               }
             }
           
            }
          }
        }
      }
    }
  }
}

.form
{
  position: relative;
  .form-error
  {
    color:$red;
  }

  .form_group
  {
    position: relative;
  }
   .error
   {
     position: absolute;
     color:$red;
     left: 0;
     font-style: italic;
   }

   .eye
   {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(50%);
      cursor: pointer;
   }
}
