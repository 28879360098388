@import "../../assets/scss/index.scss";

.cookies
{
    background-color: $green;
    color: #fff;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    padding: 10px;
    animation: fadeIn 1s;
    animation-iteration-count: 1;
   

    @keyframes fadeIn 
    {
        from 
        {
            transform: translateY(100%);
            opacity: 0;
        }
        to 
        {
           
            transform: translateY(0%);
            opacity: 1;
        }
    }

    h2
    {
        margin-bottom: 5px;
    }

    button
    {
        padding: 10px;
        border-radius: 4px;
        background-color:#fff;
        color: $green;
        display: inline-block;
        margin-top: 10px;
    }

    p
    {
        line-height: 20px;
        max-width: 85%;
    }
}
