@import "../../assets/scss/index.scss";

.back {
  width: 28px;
  height: 28px;
  background-color: $lighter-green;
  color: $green;
  border-radius: 50%;
  transform: none !important;
  font-weight: bold;
  position: absolute;
  top: 20px;

  i {
    font-size: 1.2rem;
    transform: translateX(-1px);
  }

  // @include mq("tablet", "min") {
  //   top: 44px;
  // }
}


.with_dropdown {
  display: flex;
  &.unfilled {
    input,
    .estm {
      color: rgba(48, 48, 48, 0.5);
    }
  }
  .estm {
    background: #fff;
    border: 1px solid rgba(53, 120, 229, 0.1);
    font-size: 18px;
    color: #303030;
    padding: 0px 0px 0px 5px;
    @include flex-center;
    span {
      margin-top: -8px;
    }
  }
  .dropdown {
    width: 68px;
    flex-shrink: 0;
    // padding: 0px 10px;
    background-color: rgba(51, 213, 186, 0.5);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: #303030;
    border-left: none;
    padding-left: 10px;
    margin-left: -2px;
    &:focus {
      border-left: none;
    }
  }
  &:focus-within small {
    border: 1px solid $green;
    border-right: none;
  }
}
.form-row-checkbox {
  display: flex;
  margin: 14px 0;
  input[type="checkbox"] {
    margin-right: 10px;
    flex-shrink: 0;
    position: absolute;
    clip: rect(0, 0, 0, 0);
    &:checked {
      background: transparent;
    }
  }
  label {
    font-size: 12px;
    color: #303030;
    line-height: 18px;
    display: flex;
    align-items: flex-start;
    .error{
      font-size: 12px ;
    }
    a {
      color: #33d5ba;
      text-decoration: underline;
    }
  }
  input[type="checkbox"] + label::before {
    content: "\a0"; /* non-break space */
    display: inline-block;
    vertical-align: 0.2em;
    border: 1px solid #33d5ba;
    width: 18px;
    height: 18px;
    margin-right: 0.2em;
    border-radius: 2px;
    background: #fff;
    text-indent: 0.12em;
    line-height: 1.2;
  }
  input[type="checkbox"]:checked + label::before {
    content: "\2713";
    color: #29b36a;
    font-size: 14px;
  }
}

/* OTPVIEW STYLES  */
.otpview {
  @include flex-center(column);
  .formMessage {
    width: 263px;
    margin: 51px auto 0px;

    em {
      color: #303030;
    }
  }
  .otp__box {
    margin: 50px auto 30px;
    position: relative;
    input {
      margin: 0 6px;
      width: 33px !important;
      height: 33px;
      border: 1px solid rgba(51, 213, 186, 0.5);
      border-radius: 5px;
      text-align: center;
    }
    &.error input {
      border: 1px solid red;
    }
  }
  .timer {
    color: #7a869a;
    span {
      font-weight: 600;
      font-size: 20px;
      color: #33d5ba;
      margin-left: 5px;
    }
  }
  .resend {
    font-size: 12px;
    margin: 51px 0 20px;
    color: #33d5ba;
  }
  .spinner {
    font-size: 50px;
    height: 113px;
    color: $green;
  }
  .mess {
    min-height: 70px;
  }
}

.verified {
  text-align: center;
  P {
    color: $black;
  }
  .verify_icon {
    margin: 100px 0;
  }
}

/* WALLETDETAILS STYLE*/
.walletview {
  .tsummary {
    max-width: 100%;
    width: 275px;
    margin: 38px auto 6px;
    top: 0px;
    .name {
      span {
        text-transform: uppercase;
      }
    }
    &::before {
      content: "";
      left: 0;
      top: 0px;
      position: absolute;
      width: 100vw;
      height: 270px;
      background-color: #f3fffd;
      z-index: -1;
    }
    div {
      color: $black;
      margin-bottom: 6px;
      @include flex-btw;
      align-items: flex-start;
      & > span:nth-child(1) {
        max-width: 80%;
        white-space: pre-wrap;
      }
      & > span:nth-child(2) {
        font-weight: 500;
        font-size: 16px;
        transform: translateY(-1.4px);
      }
    }
    p.edit_icon {
      transform: translateX(40%);
      color: rgba(48, 48, 48, 0.5);
      font-weight: 500;
      cursor: pointer;
    }
  }
  .user_receive_info {
    margin-top: 28px;
    text-align: center;
    .approx {
      font-size: 12px;
      color: #8b8888;
      font-style: italic;
    }
    .receive_amount {
      font-size: 16px;
      font-weight: 500;
      color: #303030;
       margin-left: 10px;
    }
  }
  .qrview {
    margin-top: 30px;
    & > p {
      @include flex-btw;
      width: 250px;
      margin: 11px auto 20px;
      color: #33d5ba;
      font-weight: 500;
      padding-top: 30px;
    }
    .qrbox {
      @include flex-btw;
      .qr {
        width: 40%;
        flex-shrink: 0;
        img {
          width: 100px;
          height: 100px;
          @include mq("tablet-small", "min") {
            width: 126px;
            height: 126px;
          }
        }
      }
      .qrinfo {
        p {
          color: #7a869a;
          font-size: 12px;
          line-height: 18px;
        }
        button {
          padding: 9px 10px 8px 9px;
          background-color: #d3efea;
          color: #33d5ba;
          margin: 3px 0;
          font-size: 10px;
          font-weight: 500;
          width: 106px;
          height: 31px;
        }
        .cpwrap {
          position: relative;
          .cindi {
            font-size: 8px;
            font-weight: 300;
            background: #33d5ba;
            color: #fff;
            z-index: 2;
            position: absolute;
            height: 27px;
            top: -25px;
          }
        }
      }
    }
  }
}

.finalStep {
  p.message {
    margin-top: 57px;
    .emph {
      font-weight: normal;
    }
  }
  & > p {
    font-size: 16px;
    color: #33d5ba;
    text-align: center;
  }
  .illustration {
    height: 161px;
    margin: 10px 0;
  }
  .footnote {
    color: #303030;
    text-align: center;
  }
  .submit {
    margin-bottom: 13px !important;
  }
  .btn_home {
    text-align: center;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    a {
      color: #33d5ba;
    }
  }
}

.calculator {
  .asset {
    margin-top: 37px;
    @include flex-btw;
    p {
      color: rgba(48, 48, 48, 0.5);
      margin-right: 10px;
    }
    .dropdown {
      width: 100px;
      height: 34.3px;
      background: rgba(153, 234, 220, 0.5);
      text-align: center;
      border-radius: 25px;
      P {
        font-weight: bold !important;
      }
      .custom-dropdown {
        padding: 8px 10px;
        min-height: 0px;
      }
    }
  }
}
//OTHERS
.error {
  color: red;
  margin-bottom: 20px;
}

.emph {
  &.bold {
    font-weight: 700;
  }
  color: $black;
}

.form_state {
  min-height: 20px;
  margin: 5px 0;
}

.mode {
  margin-top: 51px;
  button.choose-mode {
    margin: 22px auto;
    position: relative;
    background: #fff;
    width: 100%;
    text-align: start;
    font-family: "Raleway", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    color: rgba(48, 48, 48, 0.8);
    display: block;
    font-weight: 600;
    padding: 25px 18px;
    font-size: 16px;
    border-radius: 6px;
    box-shadow: 0 4px 25px 0 rgba(186, 186, 186, 0.25);
    border: solid 1px rgba(53, 120, 229, 0.1);

    &:not(:disabled):hover {
      transform: scale(1.02);
    }

    @include mq("tablet", "min") {
      padding: 25px 18px;
    }

    i {
      color: $green;
      position: absolute;
      font-size: 1.5rem;
      right: 12px;
      top: 23px;

      @include mq("tablet", "min") {
        right: 18px;
        top: 25px;
      }
    }
  }
}
.visuallyHidden {
  position: absolute;
  top: -9999999px;
  opacity: 0;
}

.homeCalc {
  height: 100%;
  text-align: center;
  width: 100%;
  padding: 50px 0px;
  .is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .form_wrapper {
    width: 100%;
    height: fit-content;
    min-height: 158px;
    margin-top: 20px;
    box-shadow: 0 4px 25px rgba(48, 48, 48, 0.1);
    background: #fff;
    padding: 28px 20px 29px;
    border-radius: 17px;
    @include flex-center(column);
    @include mq("tablet", min) {
      flex-flow: row;
      height: 134px;
    }
    .btn-green {
      margin: 18px 20px 0px;
      height: 54px;
      font-size: 14px;
      // width: 162px;
      line-height: 27px;
      min-width: 170px;
      @media (min-width: 820px) {
        padding: 14.3px 10.6px;
        font-size: 18px;
      }
    }
  }

  .form-row {
    width: 392px;
    max-width: 100%;
    margin: 10px 20px;
    text-align: left;
    .estm {
      top: 43%;
      left: 26%;
    }
    input {
      height: 54px;
      background: #fff;
    }
    .with_dropdown {
      .dropdown {
        width: 91.2px;
        background-color: #33d5ba;
      }
      input,
      .estm {
        border-width: 2px;
        border-color: #33d5ba;
      }
    }
  }
  .ref {
    margin: 54px auto 0;
    font-size: 16px;
    font-weight: 600;
    font-weight: 600;
    letter-spacing: 1.2px;
    color: #303030;
    @include mq("tablet") {
      width: 100%;
    }
  }

  @include mq("tablet") {
    .with_dropdown {
      border: 1px solid #33d5ba;
      border-radius: 6px;
    }
  }
}

.cpl {
  .illustration {
    height: 117px;
    margin: 30px 0;
  }
  .ghost {
    margin-top: 0px !important;
  }
  button.submit {
    margin: 45px 0px 5px !important;
  }
  .btn_home {
    text-align: center;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    a {
      color: #33d5ba;
    }
    &.indic8 {
      height: 10px;
      margin-bottom: 20px;
      p {
        color: $green;
        font-weight: normal;
      }
    }
  }
  .boldened {
    text-align: center;
    p {
      font-size: 14px;
      color: #303030;
      font-weight: 600;
      margin-bottom: 5px;
      &.amount {
        font-size: 18px;
        margin-top: 22px;
      }
    }
  }
}
.select_asset {
  .big_select {
    box-shadow: 0px 0px 30px rgba($color: #000000, $alpha: 0.1);
    height: 52px;
    padding: 15.5px 18px;
    font-weight: 600;
    margin-top: 16px;
  }

  .network_region {
    margin: 10px 0px;
  }

  .limit_info{
    text-align: center;
    margin:40px auto;
    p{
      font-size: 14px;
     font-weight: 600;
    }
    .select_icon{
      margin:20px 0;
    }

    .lightened{
      color:#33d5ba;
    }
  }
}
