@import '../BeginForgetPassword/PasswordRecovery.scss';

.formWrap{
    & > .form-row{
        input{
            margin-bottom: 25px;
        }
    }
    .tooltip{
        @include tooltip;
      }
    .passwordStrength{
        @include passwordStrength;
      }  
      .success-message{
          a{
              color: $green;
          }
      }
}