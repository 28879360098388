  
   header
    {
        margin-top: 60px;
        text-align: center;

        h3
        {
            margin: 0 0 5px;
            font-size: 18px;
        }

        p
        {
            margin-bottom: 30px;
            font-size: 14px;
        }
    }
