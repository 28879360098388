@import "../../../../assets/scss/index.scss";

.dashboard{
    h3{
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;
        color: $black;
        text-transform: capitalize;
    }
    .head{
        @include flex-btw($wrap:wrap);
        margin-bottom: 20px;

        .buttons{
            display: flex;
            @media (max-width:1041px) {
                margin: 20px 0 30px;
            }
            a{
                @include bannerButtons($color:#fff);
                margin: 0 10px 0;
                @include mq("phablet") {
                    width: 140px;
                }
                @include mq("phone-small") {
                    width: 110px;
                    margin: 0 6px 0;
                }
            }
        }
    }

  

    .network{
        background: #fff;
        padding: 30px;
        margin-top: 40px;
        @include mq("phone-small"){
            padding: 15px;
        }

        // @include mq("phone") {
        //     width: 88vw;
        // }

        .network__table{
            @include flex-btw($direction: column);
            width: 100%;
            h3.netHead{
                align-self: flex-start;
            }
           
            p{
                font-size: 18px;
                color: $black;
                max-width: 580px;
                min-height: 51px;
                margin: 20px 0 20px;
                line-height: 27px;
                align-self: flex-start;
                @include mq("phablet") {
                    max-width: 300px;
                    font-size: 14px;
                }
            
            }
              
            .invite{
                @include flex-btw($wrap:wrap);
                @media (min-width:321px){
                    width: 100%;
                }
                div{
                    @include flex-btw($direction:column,$wrap:wrap);
                    padding: 20px;
                    max-width: 402px;
                    margin: 15px;
                    min-height: 256px;
                    color: $green;
                    @media (max-width: 1275px) {
                        flex-grow: 1;
                      }
                    background: rgba(51, 213, 186, 0.14);
                    @include mq("phablet") {
                        max-width: 350px;
                    }
                    @include mq("phone-wide") {
                        max-width: 300px;
                    }
                    @include mq("phone-small") {
                        max-width: 230px;
                    }
                }
                .inviteLink_box{
                    color:$green;
                    a{
                        color: $green;
                        font-size: 16px;
                        font-weight: 600;
                        text-align: center;
                        @include mq("phablet") {
                            max-width: 290px;
                            word-wrap: break-word;
                        }
                        @include mq("phone-wide") {
                            max-width: 200px;
                        }
                    }
                    .copy{
                        @include bannerButtons($bgColor: transparent, $color:$green , $width:200px);
                        font-size: 18px;
                        font-weight: 500;
                        @include mq("phablet") {
                            width: 150px;
                        }
                        @include mq("phone-wide") {
                            max-width: 100px;
                        }
                    }
                }
                .invite_qr{
                    p{
                        text-transform: capitalize;
                        max-width: 323px;
                        text-align: center;
                        font-size: 14px;
                        line-height: 21.07px;
                        @include mq("phone-wide") {
                            max-width: 200px;
                            margin-left: 30px;
                        }
                        @include mq("phone") {
                            max-width: 180px;
                        }
                        @include mq("phone-small") {
                            position: relative;
                            right: 10px;
                        }
                    }
                    .barcode{
                        width: 100px;
                        height: 100px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .services{
        background-color: #fff;
        margin-top: 40px;
        padding: 30px;
        @include flex-even($direction:column);
        div{
            @include flex-btw($wrap:wrap);
            margin: 20px 0 20px;
            width: 100%;
            p{
                line-height: 27px;
                max-width: 500px;
                margin: 15px 0 25px;
                font-size: 18px;
            }
            button{
                @include bannerButtons($bgColor: transparent, $color:$green , $width:200px);
                font-size: 18px;
                font-weight: 500;
            }
        }
    }
}