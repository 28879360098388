.accordions {
  margin: 120px auto;
  width: 60%;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  list-style: none;
  border-radius: 10px;
  @media (max-width:788px){
      width:80%;
  }
  @media (max-width:400px){
      width:90%;
  }
  & > li {
    text-align: left;
    border-bottom: thin solid #dadce0;
    background-color: rgb(232, 240, 254);
    &.active{
        padding-bottom: 20px;
    }
    .list_title {
      min-height: 50px;
      color: #000;
      display: flex;
      background-color: #ffff;
      justify-content: space-between;
      align-items: center;
      padding: 10px 50px;

      &.active {
        margin-bottom: 12px;
      }
      h2 {
        font-size: 14px;
        font-weight: 500;
      }
      .rotate{
          transform: rotate(180deg);
      }
    }
    .accordion__content {
      overflow: hidden;
      padding: 0 60px;
       color:#3c4043;
       font-weight: 500;
      transition: max-height 0.4s cubic-bezier(0.2, 0.84, 0.44, 1);
    }
     @media (max-width:400px){
      .list_title{
          padding: 10px 30px;
      }
      .accordion__content{
          padding: 0px 40px;
      }
  }
  }
  & > li:first-child {
       border-radius: 10px 10px 0px 0px;
    .list_title {
      border-radius: 10px 10px 0px 0px;
    }
  }
  & > li:last-child {
    border-bottom: none;
     border-radius: 0 0 10px 10px;
    .list_title,.accordion__content {
      border-radius: 0 0 10px 10px;
    }
  }
}