@import "../../assets/scss/index.scss";

.content {
  @include flex-center(column);
  padding: 40px 0px 26px;
  text-align: center;

  .header {
    font-size: 25px;
    margin-bottom: 27px;
    font-weight: 800;
    padding: 0 4px;
    line-height: 1.3;

    @include mq("tablet-small", "min") {
      font-size: 2.2rem;
      margin-top: 30px;
    }

    @include mq("desktop", "min") {
      font-size: 42px;
      margin-top: 30px;
    }
  }

  .message {
    line-height: 1.5;
    font-size: 14px;
    font-weight: 300;
    padding: 0 calc(8vw - 10px);

    @include mq("desktop", "min") {
      font-size: 20px;
    }
  }

  .cta-row {
    margin-top: 30px;
    min-width: 60%;
    @include flex-even;
    @include mq("tablet") {
      justify-content: center;
    }
    a {
      background: $green;
      border-radius: 4px;
      color: #fff;
      padding: 0.7em 20.8px;
      font-size: 14px;
      // margin: 0 10px;
      display: inline-block;
      max-width: 200px;

      @include mq("tablet-small", "min") {
        font-size: 16px;
      }

      @include mq("fluid-out", "min") {
        margin: auto;
        font-size: 18px;
      }
    }
  }

  &.left {
    text-align: start;
    padding-top: 0;
    padding-bottom: 10px;
    @include flex-center(column);
    justify-content: flex-start;
    @include mq("desktop", "min") {
      margin: 30px 0;
    }

    .hero-text {
      margin-bottom: 53px;

      @include mq("tablet", "min") {
        margin-bottom: 0px;
      }
    }

    .home-illustration {
      text-align: center;
      flex-shrink: 0;
      img {
        max-width: 470px;
        float: none;
      }

      @media screen and (min-width:768px) and (max-width:800px) 
      {
        img {
          max-width: 400px;
        }
      }

      @include mq("tablet", "min") {
        margin-top: 40px;
        img {
          float: right;
          transform: scale(0.9);
        }
      }

      @include mq("tablet-wide", "min") 
      {

        img {
          transform: scale(1);
        }
      }

      @include mq("desktop", "min") {
        pointer-events: none;

        img {
          transform: scale(1.18) translateX(0%);
        }
      }

      @include mq("desktop-wide", "min") {
        pointer-events: none;

        img {
          transform: scale(1.12) translateX(0%);
        }
      }
    }

    @include mq("tablet", "min") {
      padding-top: 40px;
      @include flex-center;
      justify-content: space-between;
    }

    @include mq("desktop", "min") {
      justify-content: space-between;
      // padding: 50px 7vw 12px;
    }

    @include mq("desktop-wide", "min") {
      margin: 70px 0;
    }

    @include mq("excess", "min") {
      justify-content: space-between;
    }

    .header,
    .message {
      padding: 0;
    }

    .cta-row {
      margin-top: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      justify-items: center;
      margin: 25px auto;
      max-width: 400px;

      @include mq("tablet", "min") {
        margin: 56px 0 0;
      }

      @include mq("fluid-out", "min") {
        justify-items: start;
        max-width: 72%;
      }
    }

    a {
      padding: 20px 18px;
      max-width: 152px;
      width: 100%;
      text-align: center;
      font-size: 14px;
      margin: 0;
    }

    a.receive {
      padding: 20px 6px;
    }

    @include mq("phone") {
      a {
        padding: 20px 11px;
      }
    }

    .hero-text {
      margin-top: 60px;
      text-align: center;
      .text-wrap {
        @include mq("tablet", "min") {
          margin-top: 0px;
          max-width: 590px;
          text-align: left;
        }
      }
    }

    @include mq("tablet-wide", "min") {
      a {
        font-size: 18px;
        padding: 16.5px 29px;
        max-width: 200px;
      }

      a.receive {
        padding: 16px 13px;
      }
    }

    @media screen and (min-width: 1024px) and (max-width: 1200px) {
      a {
        padding: 16.5px 11px;
        margin: 5px;
        font-size: 16px;
      }

      a.receive {
        padding: 16px 5px;
      }
    }
  }

  .user {
    margin: 15px 0;
  }

  .light {
    font-family: Raleway;
    font-size: 16px;
    text-align: center;
    color: rgba(48, 48, 48, 0.5);
  }
}


.with_name{
  max-width: 401px;
  p{
    font-size: 24px !important;
    font-weight: 500;
    b{
      color:#000;
      font-weight: 500;
    }
    @media (max-width:400px){
      font-size: 20px !important;
    }
  }
  .cta a{ 
    margin: 32px 0px;
    max-width: 288px;
     @media (max-width:400px){
       padding-left: 20px;
       padding-right: 20px;
    }
  }
}

.with_error{
  .cta-row{
    max-width: 100% !important;
    width: 100%;
    button{
      width:100%;
      max-width: 300px;
     
    }
     a{
      max-width: 300px;
      
     }
  }
 
}
