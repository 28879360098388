@import "../../assets/scss/index.scss";

.Phone-row
{
    
    margin-bottom: 15px;
    label
    {
        letter-spacing: 0.42px;  
        font-weight: 600;
        margin-bottom: 6px;
        display: block;
    }

    small
    {
        color:$red;
        padding: 5px 0 0 ;
        font-weight: bold;  
        font-style: italic;   
    }

   .Phone
    {
        display: grid;
        grid-template-columns: 70px calc(100% - 70px);
        background-color: #fff;
        font-size: 16px;
        border-radius: 6px;
        border: solid 1px rgba(53, 120, 229, 0.1); 

        &.active
        {
            border: 1px solid $green;
        }

        input
        {
            font-family: 'Raleway','Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',sans-serif;
            color: rgba(48, 48, 48, 0.8);
            display: block;
            padding: 15.5px 12px;
            font-size: 16px;
            border: solid 1px rgba(53, 120, 229, 0.1);
            background-color: #ffffff;
            outline: none;
        }
          
      
        img
        {
            object-fit: cover;
        }

        button
        {
            background:none;
            transform: none !important;
        }

        .dropdwn
        {
            cursor: pointer;
            padding: 15.5px 8px;
            position: relative;
            border: solid 1px rgba(53, 120, 229, 0.1);
           

            .img
            {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 32px;
                height: 16px;
                margin: auto;
                margin-left: 5px;

                @include mq("tablet","min")
                {
                    margin-left:-2px
                }

                i
                {
                    color: $green;
                    transform: translate(6px);
                }
            }

            .list
            {
                z-index: 10;
                display: none;
                position: absolute;
                top: 100%;
                left: 0;
                list-style-type: none;
                background:#fff;
                min-width: 250px;
                max-height: 300px;
                overflow: auto;
                border: 1px solid $light-green;

                &.active
                {
                    display: initial;
                }

                li:hover
                {
                    background-color: $grey;
                }

                button
                {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 32px 1fr;
                    gap: 10px;
                    padding: 5px;
                    text-align: left;
                }
            }


            @include mq("tablet","min")
            {
                padding: 15px;

                &::after
                {
                    right: 2px;
                }
            }

        }
    }

}
