@import "../../assets/scss/index.scss";
.affiliate_flex_wrapper {
  background-color: #f6f6f6;
  min-height: 100vh;
  $sidebarWidth: 272px;
  .affiliate_sidebar {
    width: $sidebarWidth;
    height: 100%;
    flex-grow: 0;
    background-color: #fff;
    padding: 45px 0px;
    transform: translateX(-$sidebarWidth);
    transition: all 0.6s ease-in-out;
    position: fixed;
    @include styledScrollbar();
    overflow-y: auto;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    z-index: 4; /* Needs to sit above the hamburger menu icon */
    .logo {
      margin-bottom: 70px;
      padding: 0px 32px;
    }
    .close-icon {
      position: absolute;
      visibility: visible;
      top: 8px;
      right: 12px;
      cursor: pointer;
      font-size: 20px;
      color: #ddd;
    }
    ul {
      list-style: none;
    }
    &.active {
      transform: translateX(0);
    }
  }
  .affiliate_main {
    padding: 20px;
    height: 100%;
    margin-left: 0px;
    @include mq("fluid-out", "min") {
      padding: 20px 58px;
    }
    @include mq("phone-small") {
      overflow-x: hidden;
    }
  }

  @media only screen and (min-width: 46.875em) {
    .affiliate_sidebar {
      transform: translateX(0);
      height: 100%;

      .close-icon {
        visibility: hidden;
      }
    }
    .affiliate_main {
      margin-left: 0px;
      margin-left: $sidebarWidth;
    }
  }
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  top: 0;
  right: 0;
  @media only screen and (min-width: 46.875em) {
    display: none;
  }
}

.top-alert {
  height: 50px;
  padding: 10px 20px;
  width: 100%;
  background: #ff4d3d;
  box-shadow: 40px 30px 75px rgba($color: #c7c7c7, $alpha: 0.3);
  margin: 20px 0px;
  border-radius: 10px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    color: #fff;
  }
  button,.addBank {
    padding: 10px;
    border-radius: 5px;
    background: #fff;
    margin-left: 10px;
  }
}

.page_loader {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  padding: 20px;
  @include flex-center(column);
  background: #f6f6f6;
  text-align: center;
  z-index: 9000000;
  h4 {
    font-size: 20px;
    max-width: 400px;
    font-weight: 600;
    &.error {
      color: #ff4d3d;
    }
  }
  button {
    @include bannerButtons(
      $bgColor: transparent,
      $color: $green,
      $width: 200px
    );
    font-size: 18px;
    font-weight: 500;
  }
  .cry-icon {
    max-height: 300px;
    max-width: 300px;
    color: #ff4d3d;
    font-size: 200px;
  }
}
