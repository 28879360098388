.last_box {
  flex-grow: 1;
  @media (min-width: 900px) {
    max-width: 400px;
    min-width: 350px;
  }
  .flexed {
    display: flex;
    justify-content: space-between;
  }

  .user_info {
    margin-bottom: 30px;
    .top_affiliate {
      display: flex;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin-right: 13px;
      }
      p {
        font-size: 16px;
      }
      .name {
        color: rgba($color: #303030, $alpha: 0.5);
      }
      .location {
        color: #303030;
        font-weight: 600;
      }
    }

    .transaction {
      text-align: right;
      h5 {
        color: #33d5ba;
        font-size: 24px;
        font-weight: 700;
      }
      p {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .bottom_activity {
    div:last-child {
      text-align: right;
    }
    .activity {
      p {
        font-size: 14px;
        font-weight: 600;
      }
      & > p:first-child {
        color: rgba($color: #303030, $alpha: 0.5);
        font-weight: 400;
      }
    }
  }

  .last_wrapper {
    padding: 20px;
  }
}

.table_region {
  margin: 50px 15px 30px;
  box-shadow: 40px 30px 75px rgba($color: #c7c7c7, $alpha: 0.3);
  max-width: 929px;
}
