@import "../../../../assets/scss/index.scss";

.add_bank {
  position: fixed;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.51);
  z-index: 1000;
  overflow-x: hidden !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.hide {
    display: none;
  }
}

.bank_content_alert {
  background: $purewhite;
  top: 50%;
  left: 312px;
  width: 911px;
  max-width: 100%;
  display: grid;
  grid-template-columns: 100%;
  margin: 20px 0;

  div.head {
    padding: 0 30px;
    @include flex-btw;

    h2 {
      width: 100%;
      text-align: left;
      font-size: 24px;

      @include mq("phone") {
        font-size: 20px;
      }
      @include mq("phone-small") {
        font-size: 18px;
        right: 10px;
      }
    }
    i {
      position: relative;
      top: -30px;
      right: 3px;
      cursor: pointer;
    }
  }
  div.bod {
    @include flex-btw();
    padding: 0 30px;

    p {
      color: $black;
      width: 519px;
      line-height: 1.5;
      margin: 15px 0px 20px;
      font-size: 18px;
      text-align: left;
      @include mq("tablet-wide") {
        width: 400px;
        font-size: 20px;
        letter-spacing: 1px;
      }

      @include mq("phone-wide") {
        padding: 0px;
        font-size: 16px;
      }
    }
    .add {
      @include bannerButtons;
      @include mq("phone-wide") {
        position: relative;
        margin-bottom: 20px;
      }
    }
    @include mq("tablet-wide") {
      flex-wrap: wrap;

      p {
        margin: 10px 0px;
      }
    }
  }

  @media screen and (max-width: 280px) {
    .content {
      .head {
        i.fa {
          top: -40px;
          right: 5px;
        }
      }
      div.bod {
        display: block;
        p {
          width: 200px;
        }
        .add {
          margin: 0 auto;
          top: 20px;
        }
      }
    }
  }

  &.inModal {
    max-width: 90%;
    @include mq("tablet-small") {
      div.head h2,
      div.bod p {
        text-align: center;
      }
      div.bod {
        justify-content: center;
      }
    }
  }
}
