@import "../../assets/scss/index.scss";

.Info {
  color: $black;
  padding-top: 45px;
  background-position: top right;
  background-size: 300px;
  background-repeat: no-repeat;
  background-color: #fff;
  background-image: url("../../assets/images/bg-mobile-2.png");

  @include mq("tablet-small", "min") {
    background-size: unset;
    background-position: top right;
    background-image: url("../../assets/images/bg-2.png");
    min-height: 800px;
  }

  .InfoContent {
    max-width: 900px;
    margin: auto;
    padding: 0 30px;

    .pageTitle {
      margin: 60px auto 50px;
      text-align: center;
      font-size: 35px;
      font-weight: bold;
    }

    .text {
      margin-bottom: 40px;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.8;
    }

    .sh-1 {
      font-weight: bold;
      font-size: 22px;
      margin-bottom: 40px;
    }

    .sh-2 {
      font-weight: 600;
    }

    .link {
      color: $green;
      transform: none;
    }

    @include mq("tablet", "min") {
      .text {
        font-size: 20px;
        line-height: 1.6;
      }
    }
  }
}

.Faq {
  overflow: hidden;
  .logo{
    // align-self: flex-start;
    // transform: translate(20%,-70%);
    height: 70px;
    width:70px;
    border-radius:100%;
    background:#fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    z-index: 3;
    padding: 10px;
    margin-bottom: 20px;
    svg{
      height: 100%;
      width:100%;
    }
  }
  .header {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        rgba(109, 196, 242, 0.3) 50%,
        rgba(109, 196, 242, 0.3) 100%
      );
      background-repeat: repeat-x;
      background-position: center;
      background-size: 200px;
      border-radius: 0 0 50% 50%/0 0 100% 100%;
      transform: scale(1.5);
      z-index: 0;
    }
    background: url("../../assets/images/faq.png"),
      url("../../assets/images/bg.png"), url("../../assets/images/bg.png");
    background-size: 400px;
    background-repeat: no-repeat;
    background-position: center, top right, top left;
    position: relative;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 20%;
    border-bottom-right-radius: 20%;
    @media (max-width: 400px) {
      background-size: 300px;
      min-height: 250px;
    }
    .search {
      z-index: 3;
      background: #fff;
      height: 50px;
      padding: 10px 30px;
      border-radius: 10px;
      width: 40%;
      min-width: 300px;
      display: flex;
      align-items: center;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

      label {
        width: 30px;
        flex-shrink: 0;
        font-size: 20px;
        color: #ffd13c;
      }
      input {
        border: none;
        height: 100%;
        width: 100%;
        font-size: 16px;
      }
      @media (max-width: 768px) {
        width: 60%;
        min-width: auto;
      }
      @media (max-width: 400px) {
        width: 90%;
        min-width: auto;
      }
    }
  }
  .isEmpty {
    margin-top: 100px;
    h2 {
      font-size: 32px;
    }

    color: #ff4d3d;
    @media (max-width: 768px) {
      min-width: auto;
      h2 {
        font-size:20px;
      }
    }
  }
}

.faq_step {
  margin: 10px 0px 10px;
  list-style-position: inside;
  li {
    min-height: 30px;
  }
}

.coin_list {
  font-weight: 600;
  li {
    min-height: 20px;
  }
}
