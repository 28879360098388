.gravatar {
  text-align: center;
  margin: 20px 0px;
  .gravatar_image {
      position: relative;
    img {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      object-fit: cover;
    }
    margin-bottom: 10px;
    .btnUpload{
        position: absolute;
        left: 50%;
        top:50%;
        transform: translateX(-50%);
        padding: 5px 10px;
        border-radius: 10px;
        background: transparent;
        color:#fff;
        border:2px solid #fff;
        &:hover{
           background: green;
        }
    }
  }
  .gravatar_info {
    // font-family: "Mulish-Roman";
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    color: #2a2d34;
  }
}

.visually-hidden{
    visibility: hidden;
}