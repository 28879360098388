@import "../../assets/scss/index.scss";

.NotFound
{
    @include flex-center;
    min-height: 100vh;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #fff;
    background-image: url("../../assets/images/bg-mobile.png");
   
    @include mq("phone-small")
    {
        background-size: unset;
    }   
          

    @include mq("tablet","min")
    {   
        background-position: center;
        background-image: url("../../assets/images/bg.png");

    }

    .message
    {
        font-size: 1.2rem;
        font-weight: 300;
        padding: 0 calc(8vw - 10px);
        margin-top: 20px;
    }
    
    .cta-row
    {
        margin-top: 45px;
        min-width: 60%;
        @include flex-even;

        a
        {
            background:$green;
            border-radius: 4px;
            color:#fff;
            padding: 0.7em 2em;
            font-size: 14px;               
                
            @include mq("tablet-smal","min")
            {
                font-size: 16px;
            }
                
            @include mq("fluid-out","min")
            {
                margin: auto;
                font-size: 20px;
            }
        }
    }

    .error-illustration
    {
        margin-top: 50px;

                   
        @include mq("desktop","min")
        {
            margin: 10px;
            height: 300px;
        }

    }
}
