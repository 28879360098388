@import "../../assets/scss/index.scss";

.grid__comp {
  display: flex;
  flex-wrap: wrap;

  &__box {
    flex-basis: 224px;
    margin: 15px;
    display: flex;
    flex-flow: column;
    min-height: 164px;
    background-color: #fff;
    box-shadow: 40px 30px 75px rgba($color: #c7c7c7, $alpha: 0.3);
    @media (max-width: 1275px) {
      flex-grow: 1;
    }
    .box_title {
      width: 100%;
      height: 42.25px;
      text-align: center;
      padding: 20px;
      background-color: #f6fcfc;
      padding: 10px 0px;
      h3 {
        font-size: 16px;
        font-weight: 400;
        color: #303030;
      }
    }
    .box_body {
      height: 100%;
    }
  }
}

.info_count {
  height: 100%;
  text-align: center;
  padding: 10px 10px 22px;
  h2 {
    margin: 10px 0 30px;
    font-size: 32px;
    color: #33d5ba;
    font-weight: 700;
  }
  p {
    color: #303030;
    font-size: 16px;
  }
  .info_btn,button {
    @include bannerButtons($width: 170px);
    margin: 0 auto;
    height: 38px;
    color:#fff;
  }
}
