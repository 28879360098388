/* Custom Dropdown */
@import "../../../assets/scss/index.scss";


.dropdownWrapper {
    display: inline-block;
   //margin-top: 20px;
    label {
      font-size: 20px;
      font-weight: 600;
      color: #BDBDBD;
      margin-bottom: 10px;
    }
    &.full {
      width: 100%;
      // min-height: 50px;
      height:100%;
      .custom-dropdown {
         height:100%;
      }
    }
    &.md {
      width: 300px;
      @include mq("phone"){
        width: 150px;
      }
    }
    &.block {
      display: block;
    }
    .custom-dropdown {
        @include flex-center;
        padding: 0px 10px;
      position: relative;
      vertical-align: middle;
      text-align: center;
      font-size: 16px;
      width: 100%;
      height: 100%;
      min-height: 50px;
      line-height: 1.375;
      border-radius: 6px;
      color: #575757;
      margin-bottom: 0;
      font-weight: 600;
      text-align: left;
      text-transform: capitalize;
      outline: 0 !important;
      white-space: nowrap;
      border: none;
      user-select: none;
      &.invalid {
        border: 2px solid red !important;
      }
      &.ghost {
        background: transparent;
        border: 1px solid #a0a4a8;
      }
      p {
        overflow: hidden;
        font-size: 14px;
        color:$black;
        font-weight: 500;
      }
      .caret {
        position: absolute;
        top: 10px;
        right: 16px;
        margin-left: 10px;
      }
      ul {
        @include menu-style;
         background-color:#c7f0e9;
        border-radius: 0px;
        left: 0px;
        top: 44%;
        &.up{
          top:-108px;
           text-align: center;
           border-radius: 6px;
        }
        z-index: 5;
        &.full {
          width: 100%;
        }
        &.scroll {
          @include styledScrollbar;
          max-height: 300px;
        }
        li{
          border-bottom: 1px solid rgba(48, 48, 48, 0.1);
          color:rgba(48, 48, 48, 0.5);
          font-size: 12px;
          text-align:center;
          font-weight: 600;


        }
      }
      
    }
    .rotate{
          transform: rotateY(360deg);
      }
  }